
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { close, scan, cameraOutline, shieldCheckmark, } from 'ionicons/icons';

// components
import { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent,
         IonThumbnail, IonAvatar, IonLabel, IonChip, IonList, IonItem,
         IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
         IonButtons, IonButton, IonIcon, IonFab, IonFabButton, } from '@ionic/vue';
import LoadingSkeleton from '@/components/LoadingSkeleton.vue';

// API services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

import { Html5Qrcode, Html5QrcodeScanner, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { useRouter } from 'vue-router';

export default {
  name: 'CertListPage',
  components: { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent,
                IonThumbnail, IonAvatar, IonLabel, IonChip, IonList, IonItem,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonButtons, IonButton, IonIcon, IonFab, IonFabButton,
                LoadingSkeleton },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, formatDate, getRelativeDate, openBrowser, openImageModal, addResizeUrlParams, presentAlert, } = utils();
    const store = useStore();
    const router = useRouter();

    let html5QrCode;
    const isScanning = ref(false);

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const certificates = computed(() => store.state.userCertificates);
    const settings = computed(() => store.state.settings);

    function onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      console.log(`Code matched = ${decodedText}`, decodedResult);
      const slug = decodedText.split(/\.app|\.pet/).pop();
      if (slug && slug.includes("verify-certificate")) {
        router.replace(slug);
      } else {
        presentAlert(t('invalidQRCode'));
      }
      isScanning.value = false;
      html5QrCode.stop();
      html5QrCode.clear();
    }

    function onScanFailure(error) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    }

    const toggleScan = async () => {
      html5QrCode = html5QrCode || new Html5Qrcode("reader", { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ], verbose: false });
      if (!isScanning.value) {
        const config = { fps: 10, qrbox: { width: 250, height: 250 }, formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ], };
        html5QrCode.start({ facingMode: "environment" }, config, onScanSuccess, onScanFailure);
        isScanning.value = true;
      }
      else {
        isScanning.value = false;
        html5QrCode.stop();
        html5QrCode.clear();
      }
    };

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, scan, cameraOutline, shieldCheckmark,

      // variables
      loading, certificates, settings,
      html5QrCode, isScanning,

      // methods
      t, getLocalizedStr,
      formatDate, getRelativeDate,
      openBrowser, openImageModal,
      addResizeUrlParams,
      toggleScan,
    }
  }
}
